.terms-conditions {
  display: flex;
  flex-direction: column;

  .terms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    h3, ul {
      margin-bottom: 0;
    }

    ul {
      text-align: left;
      gap: 5px;
    }

    ol {
      list-style: circle;
      margin-top: 10px;
      text-align: left;
      gap: 5px;
    }
  }

  p {
    text-align: left;
  }
}