.header {
  display: flex;
  justify-content:space-between;
  align-items: center;
  // width: calc(100% - 40px);
  // position: absolute;
  top: 10px;
  z-index:2;

  @media (max-width: 648px) {
    width: 100%;
  }

  .logo {
    height: 50px;
    width: 50px;
    pointer-events: none;
    border-radius: 50%;
  }
}