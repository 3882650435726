.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;

  @media (max-width: 1024px) {
    padding: 0;
  }

  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .nav-options {
      padding-left: 25px;
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 50px;
      list-style-type: none;

      @media (max-width: 648px) {
        display: flex;
        width: 84%;
        height: auto;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        flex-direction: column;
        list-style-type: none;
        grid-gap: 0px;
        align-items: end;
        padding-bottom: 20px;

        &.active {
          background: #FFF;
          left: 0;
          opacity: 1;
          transition: all 0.5s ease;
          z-index: 1;
          align-content: center;
          padding-left: 0px;
        }
      }

      .option {
        @media (max-width: 648px) {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 10vw;
          padding: 10px 0px;
        }

        button {
         margin: 10px 0px;
         background: none;
         border: 0;
         font-size: 20px;
         color: #0171C2;
         border-bottom: 2px solid transparent;
     
         &:hover {
           text-decoration: underline;
           cursor: pointer;
         }
     
         &.selected {
           font-weight: bold;
         }
        }
     }
    }
  }

  .mobile-menu {
    display: none;

    @media (max-width: 648px) {
      display: block;

      .menu-icon {
        width: 45px;
        height: 45px;
      }
    }
  }
}
