.policies {
  .policies-list {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  ol > li::marker { content: counters(list-item, '.') '. '; }

  ul {
    list-style: disc;
    display: flex;
    gap: 5px;
    flex-direction: column;
    margin-top: 5px;
  }
}
