.tabWrapper {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 5px;

  &.vertical {
    flex-direction: row;

    .tabHeader {
      width: 30%;
      flex-direction: column;
      align-items: baseline;
    }
  }

  .tabHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    .tabItem {
      position: relative;
    
      a {
        padding: 5px 10px;
        display: block;
        text-decoration: none;
        color: #0171C2; 
      }
    
      &.selected {
        a {
          color: #0171C2;
          font-weight: bold;
        }
      }
    }
  }
  
  .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #0171C2;
  }
  
  .tabContent {
    flex: 1;
    padding: 10px;
    height: 100%;
    overflow: auto;
  }
}
