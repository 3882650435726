.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  .testimotionals {
    margin: 20px;

    .card {
      position: relative;
      overflow: hidden;
      width: 100px;
      margin: 0 auto;
      background: #eaebf2;
      padding: 20px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 10px;

      &.selected {
        background: linear-gradient(to left , #d5d8e5, #0171C2);

        .card-content{
          color: #FFF;
        }
      }

      .layer {
        z-index: 1;
        position: absolute;
        top: calc(100% - 6px);
        height: 100%;
        width: 100%;
        left: 0;
        background: linear-gradient(to left , #d5d8e5, #0171C2);
        transition: 0.5s;
      }

      .card-content {
        height: 100px;
        z-index:2; 
        font-size: 20px;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover .layer{
        top:0;
        cursor: pointer;
      }

      &:hover .card-content{
        color: #FFF;
        cursor: pointer;
      }
    }
  }
}

.error {
  min-height: 40px;
  color: #B22222;
  font-weight: bold;
  font-size: 20px;
}
