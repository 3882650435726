.main-footer {
  display: flex;
  flex-direction: column;
  background-color: #0171C2;
  margin-top: 40px;
  padding: 20px 40px;
  // position: sticky;
  // bottom: 0;

  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;

    @media (max-width: 648px) {
      flex-direction: column;
    }
  }

  .left-content {
    width: 31%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    @media (max-width: 648px) {
      width: 100%;
      align-items: baseline;
    }
  }

  .middle-content {
    width: 31%;

    @media (max-width: 648px) {
      width: 100%;
      align-items: baseline;
    }
  }

  .right-content {
    width: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;

    @media (max-width: 648px) {
      width: 100%;
      align-items: baseline;
      margin-top: 20px;
    }

    p {
      color: #FFF;
      font-weight: bold;
      min-height: 20px;
    }

    .subscribe {
      display: flex;
      flex-direction: row;
      width: 100%;

      @media (max-width: 648px) {
        flex-direction: column;
        gap: 12px;
      }
    }

    input {
      width: 100%;
      border-radius: 8px;
      height: 35px;
      margin-right: 20px;
      border: 0;
      outline: none;
      padding: 0 10px;
      font-size: 16px;

      @media (max-width: 648px) {
        margin-right: 0;
        width: auto;
      }
    }

    .footer-btn {
      padding: 5px 15px;
      border-radius: 8px;
      height: 35px;
      color: #0171C2;
      background-color: #FFF;
      margin: 0;
    }
  }

  button {
    margin: 10px 0px;
    background: none;
    border: 0;
    font-size: 20px;
    color: #FFF;
    border-bottom: 2px solid transparent;

    &.selected {
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        transition: none;
        border-color: transparent;
      }
    }

    &:hover {
      cursor: pointer;
      transition: border-color 1s ease;
      border-color: #0171C2;
    }
  }

  .copyright {
    justify-content: center;
    display: flex;
    color: #FFF;
    font-size: 14px;
  }
}