.auth {
  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .form {
    border: 1px solid #0171C2;
    border-radius: 10px;
    padding: 20px;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #0171C2;
    position: relative;
    width: 50%;

    @media (max-width: 648px) {
      width: 90%;
    }

    .auth-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;

      h4 {
        margin: 0;
        color: #fff;
      }

      p {
        color: #fff;
      }

      .link {
        color: #fff;
        font-size: 18px;
        text-decoration: none;
        background: none;
        border: none;
    
        &:hover {
          cursor: pointer;
          transition: border-color 1s ease;
          text-decoration: underline;
        }
      }
    }
  
    form {
      height: 250px;
      width: 75%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .error {
        min-height: 50px;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
      }
  
      input {
        height: 25px;
        width: 100%;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid #000;
        padding: 5px;
      }
  
      button {
        height: 30px;
        border-radius: 10px;
        background-color: #fff;
        width: 50%;
        color: #0171C2;
        font-weight: bold;
        border: 2px solid #0171C2;
        margin: 1rem;
        position: relative;
        z-index: 1;
        overflow: hidden;
  
        &:hover {
          color: #fff;
          border-color: #fff;
          cursor: pointer;
        }
  
        &::after {
          content: "";
          background: #0171C2;
          position: absolute;
          z-index: -1;
          padding: 0.85em 0.75em;
          display: block;
          transition: all 0.3s ease;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transform: scale(0, 0) rotate(-180deg);
        }
        
        &:hover::after {
          transition: all 0.3s ease-out;
          transform: scale(1, 1) rotate(0deg);
        }
  
        &.disabled {
          opacity: 0.5;
  
          &::after {
            background-color: #fff;
            transition: none;
            transform: none;
          }
  
          &:hover {
            cursor: not-allowed;
            color: #0171C2;
            border-color: #0171C2;
          }
          
          &:hover::after {
            background-color: #fff;
            color: #0171C2;
            transition: none;
            transform: none;
          }
        }
      }
    }
  
    a {
      color: #fff;
      font-size: 18px;
      text-decoration: none;
  
      &:hover {
        cursor: pointer;
        transition: border-color 1s ease;
        text-decoration: underline;
      }
    }
  }  
}
