$breakpoint-tablet: 768px;

.app {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // min-height: calc(100vh - 40px);
  min-height: calc(100vh - 230px);
  height: auto;

  .content-wrapper {
    width: 75%;
    margin: 0 auto;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .wrapper {
    width: 75%;
    max-width: 1000px;
    margin: 0 auto;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    .content {
      margin-top: 60px;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.footer-tab {
  margin-bottom: 20px;
}
