@keyframes slideIn {
  from {
    transform: translateY(70%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.home {
  animation: slideIn 1s ease-out 0.25s forwards;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-bottom: 100px;

  .header-div {
    padding-right: 50px;
    padding-left: 50px;

    .primary {
      color: #0171c2;
      text-decoration: underline;
    }
  }

  .how-works {
    padding-top: 50px;

    p {
      font-size: 18px;
    }

    h2 {
      text-align: left;
    }

    h4 {
      text-align: left;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;

    .sub-header {
      font-weight: 900;
      color: #0171c2;
    }
  }

  .header-content {
    margin: 50px 0 0;
  }

  p {
    font-size: 20px;
    text-align: left;
  }

  .form {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 20px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    form {
      height: 200px;
      width: 75%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      input {
        height: 25px;
        width: 100%;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid #000;
      }

      button {
        height: 30px;
        border-radius: 10px;
        background-color: #0171c2;
        width: 50%;
        color: white;
        border: none;
        font-weight: bold;
        border: 0.25rem solid #0171c2;
        margin: 1rem;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &:hover {
          color: #0171c2;
        }

        &::after {
          content: "";
          background: #fdfcfb;
          position: absolute;
          z-index: -1;
          padding: 0.85em 0.75em;
          display: block;
          transition: all 0.3s ease;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transform: scale(0, 0) rotate(-180deg);
        }

        &:hover::after {
          transition: all 0.3s ease-out;
          transform: scale(1, 1) rotate(0deg);
        }

        &.disabled {
          opacity: 0.5;

          &::after {
            background-color: #0171c2;
            transition: none;
            transform: none;
          }

          &:hover {
            cursor: not-allowed;
            color: #fff;
          }

          &:hover::after {
            background-color: #0171c2;
            color: #fff;
            transition: none;
            transform: none;
          }
        }
      }
    }

    a {
      color: #0171c2;
      font-size: 18px;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        transition: border-color 1s ease;
        text-decoration: underline;
      }
    }
  }

  .svg-border {
    position: absolute;
  }

  .neon {
    filter: url(#neon);
  }
}
