.teams-owned-wrapper {
  margin-top: 50px;

  .message {
    min-height: 20px;
  }

  .teams {
    display: flex;
    justify-content: start;
    align-items: baseline ;
    flex-direction: column;

    .share {
      color: #000;

      &:hover {
        color: #00B2B0;
      }
    }

    .team {
      display: flex;
      justify-content: start;
      align-items: baseline ;
      flex-direction: row;
      margin-bottom: 30px;

      .icon {
        margin-right: 10px;
        font-size: 48px;
        // color: #00B2B0;
      }
  
      .name {
        font-size: 48px;
        // color: #00B2B0;
        font-weight: 900;
      }
    }

    .members {
      padding-left: 70px;

      .member {
        display: flex;
        flex-direction: row;
        font-size: 28px;
        padding-bottom: 20px;

        &.owner {
          font-weight: 900;
          color: #0171C2;
        }

        .icon {
          margin-right: 10px;
        }

        .name {
          margin-right: 15px;
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .email {
            font-size: 22px;
          }
        }
      }
    }
  }
}