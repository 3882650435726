.about-us {
  display: flex;
  flex-direction: column;

  p {
    text-align: left;
  }

  .signature {
    span {
      display: block;
    }
  }

  .small {
    font-size: 14px;
  }
}