$breakpoint-tablet: 768px;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;

  .text {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .submit-btn {
    border-radius: 10px;
    background-color: #0171C2;
    height: 34px;
    width: 30%;
    color: white;
    border: none;
    font-weight: bold;
    border: 0.25rem solid #0171C2;
    margin: 1rem;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 648px) {
      width: 50%;
    }

    &:hover {
      color: #0171C2;
      cursor: pointer;
    }

    &::after {
      content: "";
      background: #fdfcfb;
      position: absolute;
      z-index: -1;
      padding: 0.85em 0.75em;
      display: block;
      transition: all 0.3s ease;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: scale(0, 0) rotate(-180deg);
    }
    
    &:hover::after {
      transition: all 0.3s ease-out;
      transform: scale(1, 1) rotate(0deg);
    }

    &.disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .members-list {
    width: 80%;
    margin-top: 80px;
    padding-bottom: 40px;
    position: relative;

    .estimate-toggle {
      display: flex;
      width: 100%;
      justify-content: space-around;

      .submit-btn {
        margin-left: 30px;
        width: 25%;

        @media (max-width: $breakpoint-tablet) {
          margin-left: 10px;
          width: 49%;
        }

        &.danger {
          background-color: #A71D31;
          border-color: #A71D31;

          &:hover {
            color: #A71D31;
          }

          &::after {
            background: #fdfcfb;
          }
        }
      }
    }

    .members {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 90%;
      margin: 30px auto;

      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .wizard-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        height: 35px;

        .name {
          width: 42%;
          display: flex;
          font-size: 18px;
        }

        .estimate {
          font-size: 18px;
          font-weight: bold;

          .card-bg {
            background: linear-gradient(to left , #d5d8e5, #0171C2);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .share {
    color: #00B2B0;
    margin-left: 20px;
    font-size: 21px;

    &:hover {
      cursor: pointer;
    }
  }

  .message {
    color: #0171C2;
    font-weight: bold;
  }
}
